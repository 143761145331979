



import { Vue, Component } from 'vue-property-decorator'
import SystemIndex from '@/components/system/index.vue'

@Component({ components: { SystemIndex } })
export default class ViewSystem extends Vue {

}

