import firebase, { db } from '@/plugins/firebase'

export enum UpdateStep { stop, ready, start, end }
export interface DeviceInfo {
  readonly time: Date;
  readonly driverName: string;
  readonly driverCode: string;
  readonly carType: number;
  readonly plateNo: string;
  readonly vin: string;
  readonly companyName: string;
  readonly companyNo: string;
  readonly approvalNo: string;
  readonly serialNo: string;
  readonly modelNo: string;
  readonly kFactor: number;
  readonly rpmFactor: number;
  readonly version: string;
  readonly latLng: { lat: number; lng: number };
  readonly azimuth: number;
}

interface ProgressValue {
  readonly index: number;
  readonly count: number;
}

export interface Params {
  [key: string]: string | number | null;
  carType: number | null;
  plateNo: string | null;
  vin: string | null;
  companyName: string | null;
  companyNo: string | null;
  odoOffset: number | null;
}

export class Device {
  constructor (
    public id: string = '',
    readonly info: DeviceInfo,
    readonly deviceType: number,
    readonly modemNo: string,
    readonly updatedAt?: Date,
    readonly createdAt?: Date,
    readonly receivedAt?: Date,
    public update: number = 0,
    public log: boolean| null = false,
    public firmware?: firebase.firestore.DocumentReference | null,
    readonly logCount: number = 0,
    readonly progress?: ProgressValue,
    public view?: boolean,
    public remark?: string,
    public group?: string,
    public dialog?: boolean,
    public editGroup?: boolean,
    public updateParams?: boolean | null,
    public setInfo?: Params | null
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Device> = {
  toFirestore (model: Partial<Device>, setOptions?: firebase.firestore.SetOptions) {
    if (setOptions?.merge) {
      return Object.assign(model, { updatedAt: firebase.firestore.FieldValue.serverTimestamp() })
    }

    return {
      info: model.info,
      deviceType: model.deviceType,
      modemNo: model.modemNo,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      receivedAt: firebase.firestore.FieldValue.serverTimestamp(),
      update: model.update || 0,
      updateParams: model.updateParams || false,
      params: model.setInfo || null,
      log: model.log || false,
      firmware: model.firmware,
      logCount: model.logCount || 0,
      progress: model.progress || { pos: 0, max: 0 },
      view: model.view || false,
      remark: model.remark || '',
      group: model.group || ''
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Device(
      snapshot.id,
      Object.assign({}, data.info, { time: (data.info.time as firebase.firestore.Timestamp)?.toDate() }),
      data.deviceType,
      data.modemNo,
      (data.updatedAt as firebase.firestore.Timestamp)?.toDate(),
      (data.createdAt as firebase.firestore.Timestamp)?.toDate(),
      (data.receivedAt as firebase.firestore.Timestamp)?.toDate(),
      data.update,
      data.log,
      (data.firmware as firebase.firestore.DocumentReference),
      data.logCount || 0,
      data.progress || { index: 0, count: 0 },
      data.view || false,
      data.remark || '',
      data.group || '',
      false,
      false,
      data.updateParams,
      data.setInfo
    )
  }
}
export default db.collection('devices').withConverter(converter)
