


















import { Component, Vue } from 'vue-property-decorator'
import deviceCollection, { Device } from '@/models/devices'
import modelCollection, { Model } from '@/models/models'
import firebase, { db } from '@/plugins/firebase'
// import ModelStore from '@/store/model'

  @Component
export default class SystemDevice extends Vue {
  limit = 10
  count = 0
  loading = false
  deviceId = ''
  ids: string[] = []

  async deviceIds () {
    this.loading = true
    this.count = 0
    this.ids = []
    let lastDoc: firebase.firestore.QueryDocumentSnapshot<Device> | null = null
    const ref = deviceCollection.where('info.modelNo', '==', this.deviceId).orderBy('createdAt', 'asc')
    while (this.count < 10000) {
      const sn: firebase.firestore.QuerySnapshot<Device> = !lastDoc
        ? await ref.limit(this.limit).get()
        : await ref.startAfter(lastDoc).limit(this.limit).get()
      if (sn.empty) break
      for (const doc of sn.docs) {
        this.ids.push(doc.id)
        lastDoc = doc
        this.count++
      }
    }
    this.loading = false
  }

  async removeDevices () {
    this.loading = true
    this.count = 0
    let lastDoc: firebase.firestore.QueryDocumentSnapshot<Device> | null = null
    while (this.count < 10000) {
      const sn: firebase.firestore.QuerySnapshot<Device> = !lastDoc
        ? await deviceCollection.orderBy('createdAt', 'asc').limit(this.limit).get()
        : await deviceCollection.orderBy('createdAt', 'asc').startAfter(lastDoc).limit(this.limit).get()
      // console.log(sn)
      if (sn.empty) break
      const batch = firebase.firestore().batch()
      for (const doc of sn.docs) {
        if (!doc.exists) continue
        batch.delete(doc.ref)
        const logs = await doc.ref.collection('logs').get()
        if (!logs.empty) logs.docs.forEach(log => batch.delete(log.ref))
        lastDoc = doc
        this.count++
      }
      await batch.commit()
    }
    this.loading = false
  }

  async removeEventIds () {
    this.loading = true
    this.count = 0
    let lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null = null
    const ref = db.collection('eventIds')
    const snn = await ref.limit(10).get()
    console.log(snn)
    while (this.count < 10000) {
      const sn: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = !lastDoc
        ? await ref.orderBy('createdAt', 'asc').limit(this.limit).get()
        : await ref.orderBy('createdAt', 'asc').startAfter(lastDoc).limit(this.limit).get()
      console.log(sn)
      if (sn.empty) break
      const batch = firebase.firestore().batch()
      for (const doc of sn.docs) {
        batch.delete(doc.ref)
        lastDoc = doc
        this.count++
      }
      await batch.commit()
    }
    this.loading = false
  }

  async calcDevices () {
    this.loading = true
    this.count = 0
    let lastDoc: firebase.firestore.QueryDocumentSnapshot<Device> | null = null
    const models: Model[] = []
    // ModelStore.items.forEach(model => {
    //   const newModel = new Model(
    //     model.id,
    //     0,
    //     model.firmwareCount || 0,
    //     0,
    //     0)
    //   models.push(newModel)
    // })
    // console.log(models)
    while (this.count < 10000) {
      const sn: firebase.firestore.QuerySnapshot<Device> = !lastDoc
        ? await deviceCollection.orderBy('createdAt', 'asc').limit(this.limit).get()
        : await deviceCollection.orderBy('createdAt', 'asc').startAfter(lastDoc).limit(this.limit).get()
      if (sn.empty) break
      for (const doc of sn.docs) {
        const device = doc.data()
        if (!device.info.modelNo) console.log(device)
        const findModel = models.find(m => m.id === device.info.modelNo)
        if (!findModel) {
          const newModel = new Model(
            device.info.modelNo,
            1,
            0,
            device.update > 0 ? 1 : 0,
            device.view ? 1 : 0)
          models.push(newModel)
        } else {
          findModel.deviceCount += 1
          if (device.update > 0) findModel.updateCount += 1
          if (device.view) findModel.viewCount += 1
        }
        lastDoc = doc
        this.count++
      }
    }
    const batch = firebase.firestore().batch()
    models.forEach(model => {
      const set = {
        deviceCount: model.deviceCount,
        updateCount: model.updateCount,
        viewCount: model.viewCount
      }
      console.log(set)
      batch.set(modelCollection.doc(model.id), set, { merge: true })
    })
    await batch.commit()
    this.loading = false
  }
}
