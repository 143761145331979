





































import { Component, Vue } from 'vue-property-decorator'
import deviceCollection, { Device } from '@/models/devices'
import firmwareCollection from '@/models/firmwares'
import firebase, { db } from '@/plugins/firebase'
import FirmwareStore from '@/store/firmware'
import delay from 'delay'

  @Component
export default class SystemUpdateDevice extends Vue {
  input = ''
  loading = false
  fullIds: string[] = []
  firmwareId: string | null = null
  update = 0
  view = false

  get firmwareItems () {
    return FirmwareStore.snapshots.map(doc => {
      return { value: doc.id, text: doc.id }
    })
  }

  get ids () {
    return this.input.split(',')
  }

  async updateAll () {
    if (!this.firmwareId) throw Error('펌웨어를 고르세요')
    if (!this.input) throw Error('아이디를 넣으세요')
    this.loading = true
    this.fullIds = this.input.split(',')
    let ids = []
    // const limit = 10
    try {
      while (this.fullIds.length) {
        const id = (this.fullIds.shift())?.trim()
        if (!id) continue
        ids.push(id)

        if (ids.length < 10) continue
        await this.updateIds(ids)
        ids = []
      }
      await this.updateIds(ids)
    } finally {
      this.loading = false
    }
    //
  }

  async updateIds (ids: string[]) {
    // console.log(ids)
    if (!this.firmwareId) return
    await delay(100)
    const sn = await deviceCollection
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids).limit(10).get()
    if (sn.empty) return
    const batch = db.batch()
    const set: Partial<Device> = {
      update: this.update,
      view: this.view,
      firmware: firmwareCollection.doc(this.firmwareId)
    }
    sn.docs.forEach(doc => {
      batch.set(doc.ref, set, { merge: true })
    })

    await batch.commit()
  }
}
