









































import { Component, Vue } from 'vue-property-decorator'
import ReceiveForm from '@/components/system/receive-form.vue'
import SystemDevice from '@/components/system/device.vue'
import SystemUpdateDevice from '@/components/system/update-device.vue'

@Component({ components: { ReceiveForm, SystemDevice, SystemUpdateDevice } })
export default class SystemIndex extends Vue {
  // async makeFirmware () {
  //   const r = await this.$api.post('/make/firmware')
  //   if (!r.data) return
  //   console.log(r.data)
  // }

  // async makeDevice () {
  //   const r = await this.$api.post('/make/device')
  //   if (!r.data) return
  //   console.log(r.data)
  // }
  get xs () { return this.$vuetify.breakpoint.xs }

  get isDev () {
    return process.env.NODE_ENV === 'development'
  }

  async dbInit () {
    const r = await this.$api.post('/system/db-init')
    if (!r.data) return
    console.log(r.data)
  }

  async algoliaSetup () {
    const r = await this.$api.post('/system/algolia-setup')
    if (!r.data) return
    console.log(r.data)
  }
}
