


























import { Component, Vue } from 'vue-property-decorator'
import { rdb } from '@/plugins/firebase'

interface ReceiveJSON {
  readonly active: boolean;
  readonly connectLog: boolean;
  readonly infoLog: boolean;
  readonly packetLog: boolean;
  readonly import: number;
}

@Component<ReceiveForm>({
  created () {
    this.ref.on('value', (sn) => {
      const d = sn.val()
      if (!d) {
        this.receive = {
          active: false,
          connectLog: false,
          infoLog: false,
          packetLog: false,
          import: 0
        }
        return
      }
      this.receive = d
    })
  },
  destroyed () {
    this.ref.off()
  }
})
export default class ReceiveForm extends Vue {
  ref = rdb.ref('receive')
  receive: ReceiveJSON = {
    active: false,
    connectLog: false,
    infoLog: false,
    packetLog: false,
    import: 0
  }

  get isDev () {
    return process.env.NODE_ENV === 'development'
  }

  submit () {
    this.ref.set(this.receive)
  }
}
